import { addLocaleData } from "react-intl";
import enLocaleData from "react-intl/locale-data/en";
import nbLocaleData from "react-intl/locale-data/nb";
import * as translations from "./translations";

import enTranslationMessages from "../../translations/en.json";
import nbTranslationMessages from "../../translations/nb.json";

addLocaleData(enLocaleData);
addLocaleData(nbLocaleData);

export const DEFAULT_LOCALE = "en";

export const appLocales = ["en", "nb"];

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};

  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  }, {});
};

export const translationMessages = {
  en: formatTranslationMessages("en", enTranslationMessages),
  nb: formatTranslationMessages("nb", nbTranslationMessages)
};

export { translations };
