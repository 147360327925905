import React from "react";
import PropTypes from "prop-types";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { FormElement, LabelText, FieldError } from "../Form";
import { Input } from "../TextInput";

// Hack for styled-component's inner ref thingie
class InputWithFocus extends React.Component {
  focus = () => {
    this.input.focus();
  };

  render() {
    return <Input innerRef={el => (this.input = el)} {...this.props} />;
  }
}

export const DatePicker = ({
  className,
  field,
  form: { touched, errors, setFieldValue, setFieldError },
  label,
  ...props
}) => (
  <FormElement className={className}>
    <label>
      <LabelText>{label}</LabelText>
      <DayPickerInput
        onDayChange={date => setFieldValue(field.name, date)}
        onBlur={field.onBlur}
        value={field.value}
        component={InputWithFocus}
        {...props}
      />
      {touched[field.name] &&
        errors[field.name] && <FieldError>{errors[field.name]}</FieldError>}
    </label>
  </FormElement>
);

DatePicker.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};
