import React from "react";
import PropTypes from "prop-types";
import Recaptcha from "react-recaptcha";
import { injectIntl, intlShape } from "react-intl";
import { getReCaptchaSiteKey } from "config.js";
import { FormElement, LabelText, FieldError } from "../Form";
import messages from "./messages";

const RecaptchaField = injectIntl(
  ({
    className,
    field,
    intl: { formatMessage },
    form: { touched, errors, setFieldValue, setFieldError },
    label,
    ...props
  }) => (
    <FormElement className={className}>
      <label>
        <LabelText>{label || formatMessage(messages.label)}</LabelText>

        <Recaptcha
          sitekey={getReCaptchaSiteKey()}
          verifyCallback={response => {
            setFieldValue(field.name, response);
          }}
          expiredCallback={response => {
            setFieldValue(field.name, "");
            setFieldError(field.name, formatMessage(messages.expired));
          }}
        />

        {touched[field.name] &&
          errors[field.name] && <FieldError>{errors[field.name]}</FieldError>}
      </label>
    </FormElement>
  )
);

RecaptchaField.propTypes = {
  intl: intlShape,
  label: PropTypes.string,
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

export { RecaptchaField };
