import { init } from "@rematch/core";
import { model as authModel } from "modules/auth";
import createRematchPersist from "@rematch/persist";

let store;

export const configureStore = () => {
  if (store) {
    return store;
  }

  const persistPlugin = createRematchPersist({
    whitelist: ["auth"],
    throttle: 15,
    version: 2
  });

  store = init({
    plugins: [persistPlugin],
    models: {
      auth: authModel
    }
  });

  return store;
};
