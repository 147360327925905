import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Flex, Box } from "@rebass/grid";
import { Button } from "components/Buttons";
import { connect } from "react-redux";
import { userAuthSelector } from "modules/auth";
import { Small, Desktop } from "components/Responsive";
import { Logo } from "components/Logo";
import { FormattedMessage } from "react-intl";
import { translations } from "modules/localization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Username = styled.span`
  font-size: 17px;
  color: #555;
`;

const PrintFriendlyContactInfo = styled(Flex)`
  @media print {
    display: none;
  }
`;

class Header extends React.Component {
  static propTypes = {
    isAuthed: PropTypes.bool.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    onLogout: PropTypes.func.isRequired,
  };

  render() {
    const { isAuthed, firstName, lastName, onLogout } = this.props;

    return (
      <React.Fragment>
        <Flex style={{ marginBottom: "50px" }}>
          <Desktop>
            <Box width={200}>
              <Link to="/">
                <Logo width="200" />
              </Link>
            </Box>
          </Desktop>
          <Small>
            <Box width={100} mr={2}>
              <Link to="/">
                <Logo width="100" />
              </Link>
            </Box>
          </Small>

          <Box ml="auto" alignItems="center">
            <PrintFriendlyContactInfo>
              {isAuthed && (
                <div
                  style={{
                    marginLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Username>
                    {firstName} {lastName}
                  </Username>{" "}
                  <Button ml={2} primary size="small" onClick={onLogout}>
                    <FormattedMessage {...translations.actions.logout} />{" "}
                    <FontAwesomeIcon icon="sign-out-alt" />
                  </Button>
                </div>
              )}
            </PrintFriendlyContactInfo>
          </Box>
        </Flex>
      </React.Fragment>
    );
  }
}

Header = connect(
  (state) => ({
    isAuthed: userAuthSelector(state).isAuthed,
    firstName: userAuthSelector(state).firstName,
    lastName: userAuthSelector(state).lastName,
  }),
  (dispatch) => ({
    onLogout: () => dispatch.auth.onLogout(),
  })
)(Header);

export { Header };
