import React from "react";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const ErrorBoundaryFallback = ({ componentStack, error }) => (
  <div>
    <h2>
      <FormattedMessage {...messages.heading} />
    </h2>
    <p>
      <FormattedMessage {...messages.errorInstruction} />
    </p>

    <details style={{ whiteSpace: "pre-wrap" }}>
      Route: {global.location.pathname}
      <br />
      {error && error.toString()}
      <br />
      {componentStack}
    </details>
  </div>
);

export default ErrorBoundaryFallback;
