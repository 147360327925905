import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { ToastContainer } from "react-toastify";
import _ from "lodash";

// Third party CSS
import "normalize.css/normalize.css";
import "react-table/react-table.css";
import "react-toastify/dist/ReactToastify.css";
import "react-day-picker/lib/style.css";
import "semantic-ui-css/semantic.min.css";

import "./icons";
import { TokenManager } from "modules/auth";
import { PublicLayout } from "./layouts/PublicLayout";
import { AdministrationLayout } from "./layouts/AdministrationLayout";
import { LoginLayout } from "./layouts/LoginLayout";

_.mixin({ pascalCase: _.flow(_.camelCase, _.upperFirst) });

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    font-family: "Lucida Grande", "PT Sans", sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  #root {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  @media print {
    button {
      display: none;
    }
  }
`;

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route path="/admin" component={AdministrationLayout} />
            <Route path="/login" component={LoginLayout} />
            <Route path="/" component={PublicLayout} />
          </Switch>
        </Router>

        <ToastContainer autoClose={3000} />
        <TokenManager />
        <GlobalStyle />
      </React.Fragment>
    );
  }
}

export default App;
