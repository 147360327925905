import { defineMessages } from "react-intl";

export default defineMessages({
  applications: {
    id: "app.AdministrationLayout.nav.applications",
    defaultMessage: "Applications"
  },
  collaborationPrograms: {
    id: "app.AdministrationLayout.nav.collaborationPrograms",
    defaultMessage: "Collaboration programs"
  },
  messageTemplates: {
    id: "app.AdministrationLayout.nav.messageTemplates",
    defaultMessage: "Message templates"
  },
  settings: {
    id: "app.AdministrationLayout.nav.settings",
    defaultMessage: "Settings"
  },
  budgetOverview: {
    id: "app.AdministrationLayout.nav.budgetOverview",
    defaultMessage: "Budget overview"
  },
  users: {
    id: "app.AdministrationLayout.nav.users",
    defaultMessage: "Users"
  }
});
