import { defineMessages } from "react-intl";

export default defineMessages({
  loginLabel: {
    id: "app.route.Login.LoginForm.loginLabel",
    defaultMessage: "Login"
  },
  usernameLabel: {
    id: "app.route.Login.LoginForm.usernameLabel",
    defaultMessage: "Username"
  },
  passwordLabel: {
    id: "app.route.Login.LoginForm.passwordLabel",
    defaultMessage: "Password"
  },
  invalidLoginMessage: {
    id: "app.route.Login.invalidLoginMessage",
    defaultMessage: "Wrong username and/or password"
  }
});
