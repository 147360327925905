import React from "react";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import { setLocale } from "yup";
import moment from "moment";
import "moment/locale/nb";

export class LanguageProvider extends React.PureComponent {
  setYupLocale() {
    setLocale({
      mixed: {
        // eslint-disable-next-line
        required: "${path} er påkrevd"
      },
      number: {
        /* eslint-disable */
        min: "${path} må være større enn eller lik ${min}",
        max: "${path} må være mindre enn eller lik ${max}",
        less: "${path} må være mindre enn ${less}",
        more: "${path} må være større enn ${more}",
        notEqual: "${path} kan ikke være lik ${notEqual}",
        positive: "${path} må være et positivt tall",
        negative: "${path} må være et negativt tall",
        integer: "${path} må være et heltall"
      },
      string: {
        // eslint-disable-next-line
        email: "${path} må være en gyldig e-postadresse",
        min: "${path} må minst ha ${min} tegn",
        max: "${path} kan maks ha ${max} tegn",
        length: "${path} må ha ${length} tegn"
      }
    });
  }

  componentDidMount() {
    this.setYupLocale();
    moment.locale("nb");
  }

  render() {
    return (
      <IntlProvider
        locale={this.props.locale}
        key={this.props.locale}
        messages={this.props.messages[this.props.locale]}
      >
        {React.Children.only(this.props.children)}
      </IntlProvider>
    );
  }
}

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired
};

export default LanguageProvider;
