import React from "react";
import { FormElement, LabelText, FieldError } from "../Form";
import { Radio } from "semantic-ui-react";

export const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}) => {
  return (
    <div>
      <Radio
        name={name}
        id={id}
        value={id}
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        {...props}
      />
    </div>
  );
};

export const RadioButtonGroup = ({
  value,
  error,
  touched,
  id,
  label,
  className,
  children
}) => {
  return (
    <FormElement className={className}>
      <LabelText>{label}</LabelText>
      {children}
      {touched && error && <FieldError>{error}</FieldError>}
    </FormElement>
  );
};
