import { defineMessages } from "react-intl";

export default defineMessages({
  heading: {
    id: "app.components.ErrorBoundaryFallback.heading",
    defaultMessage: "Something went wrong :("
  },
  errorInstruction: {
    id: "app.components.ErrorBoundaryFallback.errorInstruction",
    defaultMessage: "Details below can be helpful when you contact support."
  }
});
