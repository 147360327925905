import React from "react";
import PropTypes from "prop-types";
import { LoginForm } from "./components/LoginForm";
import { FormattedMessage } from "react-intl";
import { PageTitle } from "components/Typography/Headings";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { userAuthSelector } from "modules/auth";
import messages from "./messages";

class LoginPage extends React.Component {
  static propTypes = {
    isAuthed: PropTypes.bool.isRequired
  };

  render() {
    const { onLoginSuccess, isAuthed } = this.props;

    return (
      <React.Fragment>
        <PageTitle>
          <FormattedMessage {...messages.pageTitle} />
        </PageTitle>

        {!isAuthed && (
          <LoginForm
            setToken={onLoginSuccess}
            onLoginSuccess={() => this.props.history.push("/admin")}
          />
        )}

        {isAuthed && <Redirect to="/admin" />}
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    isAuthed: userAuthSelector(state).isAuthed
  }),
  dispatch => ({
    onLoginSuccess: token => dispatch.auth.onLoginSuccess(token)
  })
)(LoginPage);
