import { toast } from "react-toastify";

export const errorHandler = (error, values, actions) => {
  if (error.response) {
    // Check if validation error type if not, proably just toast it
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    toast.error(error.message, {
      autoClose: false
    });
  } else if (error.request) {
    toast.error(error.message);
  } else {
    toast.error(error.message);
  }

  actions.setSubmitting(false);
};
