import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Route, Switch } from "react-router-dom";
import { Link } from "react-router-dom";
import media from "styled-media-query";
import { Logo } from "components/Logo";
import LoginPage from "routes/Admin/Login";

const Header = styled.header`
  text-align: center;
  margin-bottom: 50px;
`;

const PageWrapper = styled.div`
  width: 100%;
  padding: 20px;

  ${media.greaterThan("large")`
    width: 360px;
    margin: 20px auto;
    padding: 0px;
  `};
`;

class LoginLayout extends React.Component {
  static propTypes = {
    children: PropTypes.any,
  };

  render() {
    return (
      <PageWrapper>
        <Header>
          <Link to="/">
            <Logo width={200} />
          </Link>
        </Header>
        <main>
          <Switch>
            <Route path="/login" component={LoginPage} />
          </Switch>
        </main>
      </PageWrapper>
    );
  }
}

export { LoginLayout };
