import React from "react";
import styled from "styled-components";
import initials from "initials";
import { space } from "styled-system";

const Badge = styled.div`
  border-radius: 100%;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  font-size: ${props => Math.floor(props.size / 3)}px;
  font-family: Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-align: center;
  line-height: ${props => props.size}px;
  display: inline-block;
  background-color: #ebf5f7;
  color: #333;
  ${space};
`;

export const NameBadge = ({ name, icon, ...props }) => (
  <Badge size={40} {...props}>
    {name && initials(name)}
    {icon && icon}
  </Badge>
);
