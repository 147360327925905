import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, NavLink, Redirect } from "react-router-dom";
import { PrivateRoute } from "modules/auth";
import { Loadable } from "lib/Loadable";
import { Logo } from "components/Logo";
import { Header } from "./Header";
import { Flex, Box } from "@rebass/grid";
import { Small, Desktop } from "components/Responsive";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import messages from "./messages";
import {
  NavigationItem,
  NavigationList,
  Sidebar,
  PageWrapper,
  StyledMotionDrawer,
  SidebarToggler,
} from "./elements";

import ErrorBoundary from "react-error-boundary";
import ErrorBoundaryFallback from "components/ErrorBoundaryFallback";

export const PageTitleContext = React.createContext({
  pageTitle: null,
  setPageTitle: () => null,
});

const AsyncApplicationsOverview = Loadable({
  loader: () => import("routes/Admin/ApplicationsOverview"),
});

const AsyncApplicationDetails = Loadable({
  loader: () => import("routes/Admin/ApplicationDetails"),
});

const AsyncCollaborationPrograms = Loadable({
  loader: () => import("routes/Admin/CollaborationPrograms"),
});

const AsyncCollaborationProgramDetails = Loadable({
  loader: () => import("routes/Admin/CollaborationProgramDetails"),
});

const AsynMessageTemplates = Loadable({
  loader: () => import("routes/Admin/MessageTemplates"),
});

const AsynSettingsPage = Loadable({
  loader: () => import("routes/Admin/Settings"),
});

const AsyncBudgetOverviewPage = Loadable({
  loader: () => import("routes/Admin/BudgetOverview"),
});

const AsyncUsersOverviewPage = Loadable({
  loader: () => import("routes/Admin/UsersOverview"),
});

class AdministrationLayout extends React.Component {
  static propTypes = {
    children: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {
      drawerIsOpen: false,
      pageTitle: {
        title: null,
        setPageTitle: this.setPageTitle,
      },
    };
  }

  setPageTitle = (title) =>
    this.setState({
      pageTitle: { title: title, setPageTitle: this.setPageTitle },
    });

  onSetOpen = (isOpen) => this.setState({ drawerIsOpen: isOpen });
  closeDrawer = () => this.onSetOpen(false);

  renderNavItems() {
    return (
      <NavigationList>
        <NavigationItem>
          <NavLink
            onClick={this.closeDrawer}
            to="/admin/applications?status=new"
          >
            <FontAwesomeIcon icon={["far", "clipboard"]} />{" "}
            <FormattedMessage {...messages.applications} />
          </NavLink>
        </NavigationItem>

        <NavigationItem>
          <NavLink
            onClick={this.closeDrawer}
            to="/admin/collaboration-programs"
          >
            <FontAwesomeIcon icon={["far", "file-alt"]} />{" "}
            <FormattedMessage {...messages.collaborationPrograms} />
          </NavLink>
        </NavigationItem>

        <NavigationItem>
          <NavLink onClick={this.closeDrawer} to="/admin/message-templates">
            <FontAwesomeIcon icon={["far", "envelope"]} />{" "}
            <FormattedMessage {...messages.messageTemplates} />
          </NavLink>
        </NavigationItem>

        <NavigationItem>
          <NavLink onClick={this.closeDrawer} to="/admin/budgets">
            <FontAwesomeIcon icon={["far", "chart-bar"]} />{" "}
            <FormattedMessage {...messages.budgetOverview} />
          </NavLink>
        </NavigationItem>

        <NavigationItem>
          <NavLink onClick={this.closeDrawer} to="/admin/settings">
            <FontAwesomeIcon icon={["far", "edit"]} />{" "}
            <FormattedMessage {...messages.settings} />
          </NavLink>
        </NavigationItem>
        <NavigationItem>
          <NavLink onClick={this.closeDrawer} to="/admin/users">
            <FontAwesomeIcon icon={["fa", "users"]} />{" "}
            <FormattedMessage {...messages.users} />
          </NavLink>
        </NavigationItem>
      </NavigationList>
    );
  }

  renderMain() {
    return (
      <main style={{ padding: "10px 20px" }}>
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
          <Switch>
            {/* Applications */}
            <PrivateRoute
              path="/admin/applications/:applicationId"
              component={AsyncApplicationDetails}
            />

            <PrivateRoute
              path="/admin/applications"
              component={AsyncApplicationsOverview}
            />

            <PrivateRoute
              path="/admin/message-templates"
              component={AsynMessageTemplates}
            />

            {/* Collaboration programs */}
            <PrivateRoute
              path="/admin/collaboration-programs/:collaborationProgramId"
              component={AsyncCollaborationProgramDetails}
            />
            <PrivateRoute
              path="/admin/collaboration-programs"
              component={AsyncCollaborationPrograms}
            />

            <PrivateRoute
              path="/admin/budgets"
              component={AsyncBudgetOverviewPage}
            />

            <PrivateRoute path="/admin/settings" component={AsynSettingsPage} />
            <PrivateRoute
              path="/admin/users"
              component={AsyncUsersOverviewPage}
            />

            {/* Default route when nothing matches */}
            <Route
              render={() => <Redirect to="/admin/applications?status=new" />}
            />
          </Switch>
        </ErrorBoundary>
      </main>
    );
  }

  render() {
    return (
      <PageTitleContext.Provider value={this.state.pageTitle}>
        <PageWrapper>
          <Small>
            <StyledMotionDrawer
              open={this.state.drawerIsOpen}
              onChange={this.onSetOpen}
            >
              <nav>{this.renderNavItems()}</nav>
            </StyledMotionDrawer>
          </Small>

          <Flex style={{ height: "100vh" }}>
            <Box width={[1]} order={2}>
              <header>
                <Header />
                <Small>
                  <SidebarToggler onClick={() => this.onSetOpen(true)}>
                    <FontAwesomeIcon icon="bars" />
                  </SidebarToggler>
                </Small>
              </header>
              {this.renderMain()}
            </Box>

            <Desktop>
              <Box width={[350]} order={1}>
                <Sidebar>
                  <div style={{ textAlign: "center", padding: "20px 0" }}>
                    <Logo width="180" style={{ maxWidth: "100%" }} />
                  </div>

                  <nav>{this.renderNavItems()}</nav>
                </Sidebar>
              </Box>
            </Desktop>
          </Flex>
        </PageWrapper>
      </PageTitleContext.Provider>
    );
  }
}

export { AdministrationLayout };
