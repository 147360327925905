import React from "react";
import styled from "styled-components";
import { FormElement, LabelText, FieldError } from "../Form";

export const Input = styled.input`
  border: 3px solid ${props => props.theme.colors.primaryLighter};
  display: block;
  padding: 12px;
  width: 100%;
  max-width: 100%;
`;

export const TextArea = Input.withComponent("textarea");

const TextInput = React.forwardRef(
  (
    {
      className,
      field,
      form: { touched, errors },
      type,
      label,
      multiLine,
      innerRef,
      ...props
    },
    ref
  ) => (
    <FormElement className={className}>
      <label>
        <LabelText>{label}</LabelText>

        {!multiLine && (
          <Input type={type} {...field} {...props} ref={innerRef || ref} />
        )}

        {multiLine && <TextArea {...field} {...props} ref={innerRef || ref} />}

        {touched[field.name] && errors[field.name] && (
          <FieldError>{errors[field.name]}</FieldError>
        )}
      </label>
    </FormElement>
  )
);

TextInput.defaultProps = {
  type: "text",
  multiLine: false
};

export { TextInput };
