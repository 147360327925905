import { defineMessages } from "react-intl";

export default defineMessages({
  expired: {
    id: "app.form.recaptcha.expired",
    defaultMessage: "Expired. Please re-verify."
  },
  label: {
    id: "app.form.recaptcha.label",
    defaultMessage: "Are you a robot?"
  }
});
