import React from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { TextInput, errorHandler } from "lib/forms";
import * as Yup from "yup";
import { Button } from "components/Buttons";
import { injectIntl, intlShape } from "react-intl";
import { login } from "modules/auth";
import messages from "./messages";

const LoginForm = injectIntl(
  ({ intl: { formatMessage }, setToken, onLoginSuccess, ...props }) => (
    <Formik
      initialValues={{
        username: "",
        password: ""
      }}
      validationSchema={props =>
        Yup.object().shape({
          username: Yup.string()
            .label(formatMessage(messages.usernameLabel))
            .required(),
          password: Yup.string()
            .label(formatMessage(messages.passwordLabel))
            .required()
        })
      }
      onSubmit={(values, actions) => {
        actions.setSubmitting(true);

        login(values.username, values.password)
          .then(response => {
            actions.setSubmitting(false);

            setToken(response.data.token);
            onLoginSuccess();
          })
          .catch(error => {
            if (error.response && error.response.status === 401) {
              actions.setErrors({
                password: formatMessage(messages.invalidLoginMessage)
              });
            } else {
              errorHandler(error, values, actions);
            }
            actions.setSubmitting(false);
          });
      }}
      render={({ values, isSubmitting }) => (
        <Form>
          <Field
            name="username"
            component={TextInput}
            label={formatMessage(messages.usernameLabel)}
          />
          <Field
            name="password"
            type="password"
            component={TextInput}
            label={formatMessage(messages.passwordLabel)}
          />

          <div style={{ textAlign: "right" }}>
            <Button
              fullWidth
              primary
              mt={2}
              type="submit"
              disabled={isSubmitting}
            >
              {formatMessage(messages.loginLabel)}
            </Button>
          </div>
        </Form>
      )}
      {...props}
    />
  )
);

LoginForm.propTypes = {
  intl: intlShape,
  onLoginSuccess: PropTypes.func.isRequired
};

export { LoginForm };
