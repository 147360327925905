import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Route, Switch } from "react-router-dom";
import { Loadable } from "lib/Loadable";
import media from "styled-media-query";
import ErrorBoundary from "react-error-boundary";
import ErrorBoundaryFallback from "components/ErrorBoundaryFallback";
import { Header } from "../Header";

const AsyncLandingpage = Loadable({
  loader: () => import("routes/Applicant/LandingPage")
});

const AsyncSubmitApplicationPage = Loadable({
  loader: () => import("routes/Applicant/SubmitApplication")
});

const FeedbackPage = Loadable({
  loader: () => import("routes/Applicant/Feedback")
});

const PageWrapper = styled.div`
  width: 100%;
  padding: 20px;

  ${media.greaterThan("large")`
    width: 960px;
    margin: 20px auto;
    padding: 0px;
  `};
`;

class PublicLayout extends React.Component {
  static propTypes = {
    children: PropTypes.any
  };

  render() {
    return (
      <PageWrapper>
        <header>
          <Header />
        </header>
        <main>
          <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <Switch>
              <Route exact path="/" component={AsyncLandingpage} />

              <Route
                path="/feedback/:applicationId/:messageId"
                component={FeedbackPage}
              />

              <Route
                path="/apply/:programId"
                component={AsyncSubmitApplicationPage}
              />
            </Switch>
          </ErrorBoundary>
        </main>
      </PageWrapper>
    );
  }
}

export { PublicLayout };
