import React from "react";
import styled from "styled-components";
import Dropzone from "react-dropzone";
import { FormElement, FieldError, LabelText } from "../Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ATTACHMENT_MAX_SIZE,
  messages,
  bytesToMB,
} from "modules/attachments/fileSize";
import { injectIntl } from "react-intl";
import { toast } from "react-toastify";

const PlainButton = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
`;

const StyledDropZone = styled(Dropzone)`
  width: 100%;
  height: 50px;
  border: 3px dashed ${(props) => props.theme.colors.primaryLighter};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: background-color 200ms;
  cursor: pointer;
  margin: 10px 0px;
  color: #444;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const FileList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const File = injectIntl(
  ({
    className,
    field,
    form: { touched, errors, setFieldValue },
    label,
    dropZoneText,
    intl: { formatMessage },
    ...props
  }) => (
    <FormElement className={className}>
      <label>
        <LabelText>{label}</LabelText>

        <StyledDropZone
          // Fixes issue where file dialog is opened twice when clicking on label
          onClick={(e) => e.preventDefault()}
          onDrop={(filesToUpload) => {
            setFieldValue(
              field.name,
              [].concat(field.value).concat(filesToUpload)
            );
          }}
          onDropRejected={(files) => {
            const message = formatMessage(messages.attachmentFileSizeExceeded, {
              files: files.map((x) => x.name).join(", "),
              maxSize: bytesToMB(ATTACHMENT_MAX_SIZE) + " MB",
            });

            toast.error(message, {
              autoClose: 10000,
            });
          }}
          maxSize={ATTACHMENT_MAX_SIZE}
          {...props}
        >
          {dropZoneText}
        </StyledDropZone>

        {touched[field.name] && errors[field.name] && (
          <FieldError>{errors[field.name]}</FieldError>
        )}
      </label>

      {field.value && Array.isArray(field.value) && (
        <FileList>
          {field.value.map((file) => {
            return (
              <li key={file.preview}>
                <PlainButton
                  onClick={() => {
                    setFieldValue(
                      field.name,
                      field.value.filter((x) => x !== file)
                    );
                  }}
                >
                  <FontAwesomeIcon
                    style={{ color: "red" }}
                    icon="minus-circle"
                  />{" "}
                  {file.name}
                </PlainButton>
              </li>
            );
          })}
        </FileList>
      )}
    </FormElement>
  )
);

export { File };
