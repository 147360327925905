import React from "react";
import styled from "styled-components";
import { FormElement, LabelText, FieldError } from "../Form";

export const SelectInput = styled.select`
  border: 3px solid ${(props) => props.theme.colors.primaryLighter};
  display: block;
  padding: 12px;
  width: 100%;
  max-width: 100%;
  -webkit-appearance: none;
  border-radius: 0;
  background-color: #fff;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;

  &:focus {
    background-image: linear-gradient(45deg, green 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, green 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
      calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #cbcbcb;
  }
`;

const Select = ({
  className,
  field,
  form: { touched, errors },
  type,
  label,
  options,
  emptyOption,
  ...props
}) => (
  <FormElement className={className}>
    <label>
      <LabelText>{label}</LabelText>
      <SelectInput {...field} {...props}>
        {emptyOption && <option key="emptyOption">{emptyOption}</option>}
        {options.map((x) => (
          <option key={x.value} value={x.value}>
            {x.label}
          </option>
        ))}
      </SelectInput>

      {touched[field.name] && errors[field.name] && (
        <FieldError>{errors[field.name]}</FieldError>
      )}
    </label>
  </FormElement>
);

export { Select };
