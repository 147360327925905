import axios from "axios";
import { getApiRoot } from "config.js";
import { configureStore } from "store";
import { userTokenSelector } from "modules/auth";

const defaultOptions = {
  skipAuth: false
};

export function request(endpoint, verb, options = {}) {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };

  if (options.headers) {
    headers = Object.assign({}, headers, options.headers);
  }

  options = { ...defaultOptions, ...options }; // eslint-disable-line no-param-reassign

  if (options.skipAuth === false) {
    const store = configureStore();
    const tokenState = userTokenSelector(store.getState());

    if (tokenState !== null) {
      headers["Authorization"] = `Bearer ${tokenState.raw}`;
    }
  }

  let callURL = getApiRoot() + endpoint + "?api-version=1.0";
  if (options.query) {
    callURL = `${callURL}&${options.query}`;
  }

  const requestConfig = {
    method: verb,
    url: callURL,
    data: options.body ? options.body : undefined,
    headers,
    responseType: options.responseType ? options.responseType : undefined
  };

  return axios(requestConfig);
}
