import { request } from "modules/api";

export const login = (username, password) =>
  request("/admin/token", "POST", {
    body: { username, password }
  });

export const refreshToken = token =>
  request("/admin/token", "POST", {
    body: { token }
  });
