import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { userTokenSelector } from "modules/auth";
import moment from "moment";

export const isTokenExpired = expiresAt =>
  moment.unix(expiresAt).utc() < moment.utc();

export const getTimeUntilRenewal = timeAhead => tokenExpiresAt => {
  const now = moment.utc();

  const timeUntilExpiration = moment.unix(tokenExpiresAt).utc() - now;

  return timeUntilExpiration - timeAhead;
};

class TokenManager extends React.Component {
  static propTypes = {
    userToken: PropTypes.object
  };

  constructor(props) {
    super(props);

    const { userToken } = props;

    // Schedule renewal of existing token
    if (userToken) {
      this.scheduleRenewal(userToken.decoded.exp);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // We have nothing to renew. User probably logged out.
    if (_.get(this.props, "userToken", null) === null) {
      // Clear any pending scheduled renewal
      return this.unscheduleRenewal();
    }

    // New token, schedule new renewal
    if (
      _.get(prevProps, "userToken.raw") !== _.get(this.props, "userToken.raw")
    ) {
      // Clear any previous token's scheduled renewal
      this.unscheduleRenewal();

      // Schedule renewal of new token
      this.scheduleRenewal(this.props.userToken.decoded.exp);
    }
  }

  scheduleRenewal(expiresAt) {
    const { renewToken } = this.props;

    // Start renewal 1 minute before a token expires
    const timeAheadOfExpiration = 60 * 1000;

    // Time in milliseconds until the token expires
    const timeUntilRenewal = getTimeUntilRenewal(timeAheadOfExpiration)(
      expiresAt
    );

    // Token has expired
    if (isTokenExpired(expiresAt)) {
      return this.props.tokenExpired();
    }

    // Scheduling renewal
    console.log(
      "Scheduling token renewal at",
      moment()
        .add(timeUntilRenewal, "ms")
        .toString()
    );

    this.renewTimeout = setTimeout(() => renewToken(), timeUntilRenewal);
  }

  unscheduleRenewal() {
    clearTimeout(this.renewTimeout);
  }

  render() {
    return null;
  }
}

TokenManager = connect(
  state => ({
    userToken: userTokenSelector(state)
  }),
  dispatch => ({
    renewToken: () => dispatch.auth.renewToken(),
    tokenExpired: () => dispatch.auth.tokenExpired()
  })
)(TokenManager);

export { TokenManager };
