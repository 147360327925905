import { defineMessages } from "react-intl";

export default defineMessages({
  attachments: {
    id: "app.localization.form.attachments",
    defaultMessage: "Attachments"
  },
  maxSize: {
    id: "app.localization.form.maxSize",
    defaultMessage: "Max {maxSize}"
  }
});
