import React from "react";
import { Header } from "semantic-ui-react";
import { PageTitleContext } from "layouts/AdministrationLayout";

export const PageTitle = props => <Header as="h2" {...props} />;
export { Header };

class AdminPageTitle extends React.Component {
  componentDidMount() {
    this.props.setPageTitle(this.props.children);
  }

  render() {
    return null;
  }
}

const AdminPageTitleWrapper = props => (
  <PageTitleContext.Consumer>
    {data => <AdminPageTitle setPageTitle={data.setPageTitle} {...props} />}
  </PageTitleContext.Consumer>
);

export { AdminPageTitleWrapper as AdminPageTitle };
