import React from "react";
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";
import { translations } from "modules/localization";
import { refreshToken } from "./fetch";
import jwtDecode from "jwt-decode";

export const userAuthSelector = state => ({
  isAuthed: state.auth.token !== null,
  username: state.auth.token !== null ? state.auth.token.decoded.sub : null,
  firstName:
    state.auth.token !== null ? state.auth.token.decoded.firstName : null,
  lastName: state.auth.token !== null ? state.auth.token.decoded.lastName : null
});

export const userTokenSelector = state => state.auth.token;

const setToken = (state, token) => ({
  ...state,
  token: {
    raw: token,
    decoded: jwtDecode(token)
  }
});

export const model = {
  state: {
    token: null
  },
  reducers: {
    onLoginSuccess(state, token) {
      return setToken(state, token);
    },
    onTokenRenewed(state, token) {
      return setToken(state, token);
    },
    onTokenExpired(state) {
      return {
        ...state,
        token: null
      };
    },
    logout(state) {
      return {
        ...state,
        token: null
      };
    }
  },
  effects: dispatch => ({
    onLogout(payload, rootState) {
      dispatch.auth.logout();
      toast.info(<FormattedMessage {...translations.actions.loggedOut} />);
    },
    async renewToken(payload, rootState) {
      const tokenState = userTokenSelector(rootState);

      if (tokenState !== null) {
        try {
          const tokenResponse = await refreshToken(tokenState.raw);
          dispatch.auth.onTokenRenewed(tokenResponse.data.token);
        } catch (error) {
          dispatch.auth.onTokenExpired();
        }
      }
    },
    tokenExpired() {
      dispatch.auth.onTokenExpired();
    }
  })
};
