import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { translationMessages } from "modules/localization";
import LanguageProvider from "modules/localization/LanguageProvider";
import { Provider } from "react-redux";
import { configureStore } from "./store";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistStore } from "redux-persist";
import { ThemeProvider } from "styled-components";
import ErrorBoundary from "react-error-boundary";
import ErrorBoundaryFallback from "components/ErrorBoundaryFallback";

const store = configureStore();
const persistor = persistStore(store);

const render = (messages) => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider
          theme={{
            colors: {
              primary: "#1c8ecd",
              primaryMedium: "#018FD1",
              primaryLighter: "#ebf4f9",
              primaryDarker: "#1D62A5",
            },
            space: [0, 5, 10, 20, 40, 80, 160, 320, 640],
          }}
        >
          <LanguageProvider messages={messages} locale="nb">
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
              <App />
            </ErrorBoundary>
          </LanguageProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  );
};

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import("intl"));
  })
    .then(() =>
      Promise.all([
        import("intl/locale-data/jsonp/en.js"),
        import("intl/locale-data/jsonp/nb.js"),
      ])
    )
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
