const globalConfig = global.COLLABORATION_PORTAL;
const settings = globalConfig.settings;

export function getApiRoot() {
  return settings.apiRoot;
}

export function getReCaptchaSiteKey() {
  return settings.reCaptcha.siteKey;
}
