import { defineMessages } from "react-intl";

/** Max size per individual attachment (bytes) */
export const ATTACHMENT_MAX_SIZE = 10 * 10 ** 6; // 10 MB

/** Max allowed sum of all attachments' file size (bytes) */
export const ATTACHMENTS_TOTAL_MAX_SIZE = 10 * 10 ** 6; // 10 MB

export const bytesToMB = (bytes) => bytes / 10 ** 6;

export const messages = defineMessages({
  totalMaxSizeExceeded: {
    id: "app.attachments.totalMaxSizeExceeded",
    defaultMessage:
      "Sum of attachment file sizes exceeds allowed total ({total})",
  },
  attachmentFileSizeExceeded: {
    id: "app.attachments.attachmentFileSizeExceeded",
    defaultMessage: "Files ({files}) exceeds allowed max size ({maxSize})",
  },
});

export const validateSize = (formatMessage) => [
  "is-valid-size",
  (d) =>
    formatMessage(messages.totalMaxSizeExceeded, {
      total: `${bytesToMB(ATTACHMENTS_TOTAL_MAX_SIZE)} MB`,
    }),
  (value) => {
    const sumFileSizes = value.reduce((acc, curr) => acc + curr.size, 0);

    return sumFileSizes < ATTACHMENTS_TOTAL_MAX_SIZE;
  },
];
