import React, { Component } from "react";
import PropTypes from "prop-types";
import { Fetch } from "react-request";
import qs from "qs";
import _ from "lodash";
import { toast } from "react-toastify";
import { getApiRoot } from "config.js";
import { configureStore } from "store";
import { userTokenSelector } from "modules/auth";

const apiVersion = "1.0";

// Wrapper for react-request's Fetch in order to easily use with Vigilo API
class VigiloFetch extends Component {
  static propTypes = {
    skipAuth: PropTypes.bool,
    // If true then it won't show errors in toaster notification
    suppressError: PropTypes.bool,
    url: PropTypes.string,
    headers: PropTypes.object,
    onError: PropTypes.func,
    query: PropTypes.object,
  };

  static defaultProps = {
    skipAuth: false,
    suppressError: false,
    headers: {},
    query: {},
  };

  render() {
    const {
      skipAuth,
      url,
      beforeFetch,
      afterFetch,
      onError,
      query,
      headers,
      suppressError,
      ...props
    } = this.props;

    const finalHeaders = {
      "Content-Type": "application/json",
      "Cache-control": "no-cache",
      Pragma: "no-cache",
      Expires: 0,
      ...headers,
    };

    const queries = qs.stringify({
      ...query,
      "api-version": apiVersion,
    });

    const finalBeforeFetch = (options) => {
      const {
        init: { body },
      } = options;

      if (finalHeaders["Content-Type"] === "application/json" && body) {
        // eslint-disable-next-line
        options.init.body = JSON.stringify(body);
      }

      // Add Authorization header
      if (skipAuth === false) {
        const store = configureStore();
        const tokenState = userTokenSelector(store.getState());

        if (tokenState !== null) {
          options.init.headers = {
            ...options.init.headers,
            Authorization: `Bearer ${tokenState.raw}`,
          };
        }
      }

      if (beforeFetch) {
        beforeFetch(options);
      }
    };

    const finalAfterFetch = (options) => {
      if (afterFetch) {
        afterFetch(options);
      }

      if (options.failed) {
        if (_.isFunction(onError)) {
          onError(options);
        } else if (suppressError === false) {
          if (
            options.error &&
            options.error.name &&
            options.error.name === "AbortError"
          ) {
            console.log("Request aborted");
          } else {
            const response = options.response;
            console.log(response);
            let message = _.get(response, "data.Message", null);

            const validationErrors = _.get(response, "data.validationErrors");

            if (Array.isArray(validationErrors)) {
              message += validationErrors.map((x) => x.message).join(". ");
            }

            if (!message) {
              message = `${_.get(
                response,
                "statusText",
                _.get(options, "error.message")
              )}: ${options.url}`;
            }

            toast.error(message);
          }
        }
      }
    };

    const urlWithApiroot = `${getApiRoot()}${url}?${queries}`;

    return (
      <Fetch
        url={urlWithApiroot}
        beforeFetch={finalBeforeFetch}
        afterFetch={finalAfterFetch}
        headers={finalHeaders}
        fetchPolicy="cache-and-network"
        {...props}
      />
    );
  }
}

export { VigiloFetch as Fetch };
