import { defineMessages } from "react-intl";

export default defineMessages({
  saved: {
    id: "app.localization.actions.saved",
    defaultMessage: "Saved"
  },
  save: {
    id: "app.localization.actions.save",
    defaultMessage: "Save"
  },
  send: {
    id: "app.localization.actions.send",
    defaultMessage: "Send"
  },
  sent: {
    id: "app.localization.actions.sent",
    defaultMessage: "Sent"
  },
  submit: {
    id: "app.localization.actions.submit",
    defaultMessage: "Submit"
  },
  delete: {
    id: "app.localization.actions.delete",
    defaultMessage: "Delete"
  },
  filter: {
    id: "app.localization.actions.filter",
    defaultMessage: "Filter"
  },
  login: {
    id: "app.localization.actions.login",
    defaultMessage: "Login"
  },
  logout: {
    id: "app.localization.actions.logout",
    defaultMessage: "Logut"
  },
  loggedOut: {
    id: "app.localization.actions.loggedOut",
    defaultMessage: "Logged out"
  },
  cancel: {
    id: "app.localization.actions.cancel",
    defaultMessage: "Cancel"
  }
});
