/*
  Add needed font awesome icons here.
*/
import fontawesome from "@fortawesome/fontawesome";

import {
  faPaste,
  faBars,
  faPaperclip,
  faSignOutAlt,
  faUsers,
  faMinusCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faChartBar } from "@fortawesome/free-regular-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";

fontawesome.library.add(
  faMinusCircle,
  faTimesCircle,
  faPaste,
  faEdit,
  faBars,
  faPaperclip,
  faChartBar,
  faEnvelope,
  faFileAlt,
  faChartBar,
  faClipboard,
  faSignOutAlt,
  faUsers
);
