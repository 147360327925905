import styled from "styled-components";
import { space } from "styled-system";

export const FormElement = styled.div`
  margin-bottom: 15px;
`;

export const FieldError = styled.div.attrs({ my: 1 })`
  color: #e30000;
  ${space};
`;

export const LabelText = styled.span`
  font-weight: 300;
  padding-bottom: 5px;
  display: block;
  color: #555;
  font-size: 16px;
`;
