import styled from "styled-components";
import Drawer from "react-motion-drawer";

export const PageWrapper = styled.div`
  width: 100%;
`;

export const SidebarToggler = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  background: 0;
  border: 0;
  font-size: 28px;
  cursor: pointer;
  color: #fff;
`;

export const Sidebar = styled.div`
  background-color: ${props => props.theme.colors.primaryLighter};
  height: 100%;
`;

export const StyledMotionDrawer = styled(Drawer)`
  color: #333;
  background-color: ${props => props.theme.colors.primaryLighter};
`;

export const NavigationList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const NavigationItem = styled.li`
  a {
    display: block;
    text-decoration: none;
    font-size: 16px;
    padding: 20px;
    color: #333;

    &:hover,
    &.active {
      background-color: ${props => props.theme.colors.primaryDarker};
      color: #fff;
    }
  }
`;
